<template>
  <div id="app">
    <appHeader :navActive.sync="navActive" />
    <transition :css="false" @enter="navEnter" @leave="navLeave" mode="out-in">
      <appNavigation v-if="navActive" :navActive.sync="navActive" />
    </transition>
    <div id="container">
      <div style="min-height: 100vh;">
        <transition :css="false" @enter="pageEnter" @leave="pageLeave" mode="out-in">
          <router-view />
        </transition>
      </div>
      <!-- <appBanner /> -->
      <appFooter />
    </div>

    <div class="fixed-wrap">
      <!-- 플로팅 섹션 링크 -->
      <section class="main-floating-section-link">
        <div class="main-floating-link link-work">
          <router-link to="/works" class="floating-btn">
            <span class="btn-ico ico-work"></span>
            <span class="btn-txt">See More Work</span>
            <span class="btn-arr">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="arr-svg"
              >
                <path
                  d="M7 17L17 7"
                  stroke="#2F363D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 7H17V17"
                  stroke="#2F363D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </router-link>
        </div>
        <div class="main-floating-link link-magazine">
          <router-link to="/magazine" class="floating-btn">
            <span class="btn-ico ico-magazine"></span>
            <span class="btn-txt">See All Story</span>
            <span class="btn-arr">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="arr-svg"
              >
                <path
                  d="M7 17L17 7"
                  stroke="#2F363D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 7H17V17"
                  stroke="#2F363D"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </router-link>
        </div>
      </section>
    </div>

    <!-- 플로팅 메뉴 -->
    <section class="floating-menu">
      <!-- 퀵메뉴 -->
      <div class="floating-quickmenu _floating-menu-wrap">
        <button
          type="button"
          class="quickmenu-btn _btn-open-layer _cursor-link"
          id="quickmenu-open"
          aria-label="open menu"
          @click="quickOpen"
        >
          <span class="ico-e"><i class="ico ico-f"></i><i class="ico ico-b"></i></span>
        </button>

        <div class="quickmenu-layer">
          <div class="quckmenu-layer-inner">
            <div class="tit">이모션글로벌과<br />위대함을 만들어보세요.</div>
            <ul class="btns">
              <li><router-link to="/request" class="btn-round">프로젝트 문의</router-link></li>
              <li><a :href="url" class="btn-round" download>회사 소개서 다운로드</a></li>
            </ul>
            <button
              type="button"
              class="quickmenu-btn-close _btn-close-layer _cursor-link"
              id="quickmenu-close"
              aria-label="close menu"
              @click="quickClose"
            >
              <span class="ico-x"></span>
            </button>
          </div>
        </div>
      </div>

      <!-- 30주년 -->
      <div class="floating-anniversary _floating-menu-wrap">
        <button
          type="button"
          class="anniv-btn _btn-open-layer _cursor-link"
          id="anniv-open"
          aria-label="open 30th Anniversary"
          @click="videoOpen"
        >
          <span class="txt">Motion Film</span>
          <span class="dot"></span>
          <span class="ico-anniv"><i class="ico ico-f"></i><i class="ico ico-b"></i></span>
        </button>

        <div class="anniv-layer">
          <div class="bg"></div>
          <div class="anniv-layer-inner">
            <div class="anniv-cont" style="color: #fff">
              동영상 들어갑니다.
            </div>
            <button
              type="button"
              class="anniv-btn-close _btn-close-layer _cursor-link"
              id="anniv-close"
              aria-label="close 30th Anniversary"
            >
              <span class="ico-x"></span>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="loading" v-if="loading" @wheel.prevent>
      <img :src="require('@/assets/images/loading.svg')" alt="" />
    </div>
  </div>
</template>
<script>
  import {layouts} from "@/utils/global-methods";
  import {TweenMax, Power4} from "gsap";
  import bus from "@/utils/bus";

  export default {
    name: "app-default",

    data() {
      return {
        navActive: false,
        loading: false
      };
    },
    watch: {
      "$route.fullPath"() {
        this.navActive = false;
        this.quickClose();
        console.log(this.$route.name);
        const workLink = document.querySelector(".main-floating-link.link-work");
        const magazineLink = document.querySelector(".main-floating-link.link-magazine");
        setTimeout(() => {
          TweenMax.to(magazineLink, 0.2, {
            autoAlpha: 0,
            y: 100
          });

          TweenMax.to(workLink, 0.2, {
            autoAlpha: 0,
            y: 100
          });
        }, 200);
      },
      navActive(val) {
        if (val) {
          document.querySelector("html").style.overflow = "hidden";
        } else {
          document.querySelector("html").style.overflow = "";
        }
      }
    },
    components: {
      appHeader: layouts("app-header"),
      appNavigation: layouts("app-navigation"),
      appFooter: layouts("app-footer")
      // appBanner: layouts("app-banner")
    },
    computed: {
      url() {
        return `${process.env.VUE_APP_API_URL}/api/v1/company`;
      }
    },
    methods: {
      quickOpen() {
        const quickmenu = document.querySelector(".floating-quickmenu");
        quickmenu.classList.add("_opened");
      },
      quickClose() {
        const quickmenu = document.querySelector(".floating-quickmenu");
        quickmenu.classList.remove("_opened");
      },
      navEnter(el, done) {
        // navigation bg
        const bg = el.querySelector(".bg");

        TweenMax.to(bg, 0.8, {
          y: "100%",
          ease: Power4.easeInOut
        });

        // navigation txt
        const anime = el.querySelectorAll(".anime1");
        TweenMax.to(anime, 0.8, {
          x: "0%",
          delay: 0.3,
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });

        // navigation mark
        const mark = el.querySelector(".mark-inner");
        TweenMax.to(mark, 0.8, {
          opacity: 1,
          delay: 0.3,
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });
      },
      navLeave(el, done) {
        // navigation bg
        const bg = el.querySelector(".bg");
        TweenMax.to(bg, 0.8, {
          y: "0%",
          delay: 0.2,
          ease: Power4.easeInOut
        });

        // navigation txt
        const anime = el.querySelectorAll(".anime1");
        TweenMax.to(anime, 0.8, {
          x: "-110%",
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });

        // navigation mark
        const mark = el.querySelector(".mark-inner");
        TweenMax.to(mark, 0.8, {
          opacity: 0,
          ease: Power4.easeInOut,
          onComplete: () => {
            done();
          }
        });
      },
      videoOpen() {
        // const anniv30th = document.querySelector(".floating-anniversary");
        // anniv30th.classList.add("_opened");
        // this.anniv30Tl.play();
        window.open("https://www.behance.net/emotionglobal", "_blank");
      },
      pageEnter(el, done) {
        const fadeEl = [
          el,
          document.querySelector(".banner-area"),
          document.querySelector("footer")
        ];
        TweenMax.fromTo(
          fadeEl,
          this.navActive ? 0 : 0.5,
          {opacity: 0},
          {
            opacity: 1,
            onComplete: () => {
              this.navActive = false;
              TweenMax.set(el, {clearProps: "all"});
              done();
            }
          }
        );
      },
      pageLeave(el, done) {
        const fadeEl = [
          el,
          document.querySelector(".banner-area"),
          document.querySelector("footer")
        ];
        TweenMax.fromTo(
          fadeEl,
          this.navActive ? 0 : 0.5,
          {opacity: 1},
          {
            opacity: 0,
            onComplete: () => {
              done();
            }
          }
        );
      }
    },
    mounted() {
      bus.$on("loading", status => {
        this.loading = status;
      });
    }
  };
</script>
<style lang="scss" scoped>
  .fixed-wrap {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 9;

    .main-floating-section-link {
      position: absolute;
      bottom: 100px;
      pointer-events: initial;
    }
  }

  // 플로팅 영역 폰트
  .main-floating-section-link,
  .floating-menu {
    * {
      font-family: "Noto Sans KR";
    }
  }
  // 플로팅 섹션 링크
  .main-floating-section-link {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    // z-index: 999;

    .main-floating-link {
      position: absolute;
      opacity: 0;
      // visibility: hidden;

      .floating-btn {
        position: absolute;
        left: 50%;
        bottom: -75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 12px;
        height: 52px;
        padding: 0 12px 0 6px;
        border-radius: 26px;
        background: rgba(238, 238, 238, 0.8);
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
        transform: translateX(-50%);
        backdrop-filter: blur(3px);

        > span {
          display: flex;
          transition: 0.3s ease-in-out;
        }

        .btn-ico {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #fff;
          color: #fff;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 24px;
            background: url("../assets/images/main/ico-e-red.svg") no-repeat center/contain;
            transform: translate(-50%, -50%) scale(1);
            transition: 0.3s;
          }

          &.ico-work::after {
            background-image: url(../assets/images/main/ico-work.svg);
          }

          &.ico-magazine::after {
            background-image: url(../assets/images/main/ico-magazine.svg);
          }
        }
        .btn-txt {
          color: #000;
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;
        }
        .btn-arr {
          position: relative;
          width: 18px;
          height: 18px;
          transform: translate3d(0, 0, 0);
          svg {
            width: 100%;
            height: 100%;
          }
        }

        // 버튼 hover
        // &:hover {
        // 	.btn-ico {
        // 		&::after {
        // 			// transform: translate(-50%, -50%);
        // 			animation: yoyoScale 0.3s alternate;
        // 			animation-iteration-count: 4;
        // 		}
        // 	}
        // 	.btn-txt {
        // 		color: $red;
        // 	}
        // 	.btn-arr {
        // 		transform: rotate(45deg);

        // 		svg.arr-svg path {
        // 			stroke: $red;
        // 		}
        // 	}
        // }
      }

      &.active {
        // border: 1px solid red;
        // opacity: 1;
        // visibility: visible;
      }
    }
  }

  @keyframes yoyoUp {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -3px, 0);
    }
  }

  @keyframes yoyoDown {
    0% {
      transform: translate3d(0, 5px, 0) rotate(180deg);
    }
    100% {
      transform: translate3d(0, -5px, 0) rotate(180deg);
    }
  }

  @keyframes yoyoScale {
    0% {
      transform: translate3d(-50%, -50%, 0) scale(1);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) scale(1.2);
    }
  }

  //플로팅 메뉴
  .floating-menu {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    pointer-events: none;
    z-index: 101;

    // 플로팅 퀵메뉴
    .floating-quickmenu {
      position: absolute;
      bottom: 25px;
      right: 20px;
      pointer-events: initial;

      .quickmenu-btn {
        display: flex;
        display: block;
        width: 50px;
        height: 50px;
        padding: 0;
        border: 0;
        outline: 0;
        background: transparent;
        animation: yoyoUp 0.3s infinite alternate;

        .ico-e {
          display: block;
          position: relative;
          width: 50px;
          height: 50px;
          transform: translateY(0) rotateY(0deg);
          transition: 0.3s ease-in;
          transform-style: preserve-3d;

          i.ico {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: $red;
            backface-visibility: hidden;
            transform-origin: center;
            box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
            background: $red url(../assets/images/common/ico-e.svg) no-repeat center;
            background-size: 25px;
          }
          i.ico-f {
            transform: rotateY(0deg);
          }
          i.ico-b {
            transform: rotateY(180deg);
          }
        }
      }

      .quickmenu-layer {
        * {
          font-family: "Noto Sans KR", "Arial", "Apple SD Gothic Neo", sans-serif;
        }
        position: absolute;
        bottom: 0;
        right: 0;
        width: calc(100vw - 40px);
        transform: translateY(100px);
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-in;
        .quckmenu-layer-inner {
          position: relative;
          padding: 60px 20px 20px;
          border-radius: 20px;
          background: #fff;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

          .tit {
            color: #000;
            font-family: "Noto Sans KR", "Arial", "Apple SD Gothic Neo", sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.6;
            text-align: center;
          }
          .btns {
            margin-top: 20px;
            li + li {
              margin-top: 10px;
            }
            .btn-round {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 42px;
              padding: 0 40px;
              border: 1px solid #000;
              border-radius: 28px;
              background-color: #fff;
              color: #000;
              font-size: 16px;
              font-weight: 700;
            }
          }
          .quickmenu-btn-close {
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $red;
            border: 0;
            outline: 0;

            .ico-x {
              position: relative;
              display: block;
              width: 14px;
              height: 14px;
              background: url(../assets/images/common/ico-x.svg) center/contain no-repeat;
              transform: rotate(0deg);
              transition: all 0.2s ease-in-out;
            }
          }
        }
      }

      &._opened {
        .quickmenu-layer {
          display: block;
          transform: translateY(0px);
          opacity: 1;
          visibility: visible;
          // .quickmenu-layer-inner {

          // }
        }
      }
    }

    // 30주년
    .floating-anniversary {
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      // display: none;

      .anniv-btn {
        position: absolute;
        top: 30%;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 110px;
        height: 32px;
        padding: 6px 8px 6px 10px;
        border-radius: 6px 6px 0 0;
        background: #000;
        border: 0;
        outline: none;
        transform: rotate(-90deg) translate(0, 40px);
        transform-origin: center;
        transition: 0.2s;
        pointer-events: initial;

        .ico-anniv {
          position: relative;
          width: 20px;
          height: 20px;
          transform: perspective(800px) rotateX(0deg);
          transition: 0.3s ease-in;
          transform-style: preserve-3d;
          i.ico {
            position: absolute;
            inset: 0;
            width: 20px;
            height: 20px;
            backface-visibility: hidden;
            transform-origin: center;
            &::after {
              content: "";
              position: relative;
              display: block;
              width: 100%;
              height: 100%;
              background: url(../assets/images/common/ico-e.png) no-repeat center/contain;
              transform: rotate(90deg);
            }
          }
        }
        .dot {
          display: block;
          width: 4px;
          height: 4px;
          margin-left: 4px;
          border-radius: 50%;
          background-color: $red;
        }
        .txt {
          color: #fff;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: -0.05em;
          transition: 0.3s ease-in;
        }
      }

      .anniv-layer {
        position: absolute;
        top: 0;
        left: 0;
        left: 100%;
        width: 100vw;
        height: 100vh;
        pointer-events: initial;

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background-color: #000;
          z-index: 1;
        }

        .anniv-layer-inner {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 2;
        }

        .anniv-btn-close {
          position: absolute;
          top: 20px;
          right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          background-color: $red;

          .ico-x {
            position: relative;
            display: block;
            width: 14px;
            height: 14px;
            background: url(../assets/images/common/ico-x.svg) center/contain no-repeat;
            transform: rotate(0deg);
            transition: all 0.2s ease-in-out;
          }
        }
      }

      &._opened {
        .anniv-layer {
          left: 0;
        }
      }
    }
  }
</style>
